import React from 'react';

const MonitorTaskAddContainer = Loader.loadModuleComponent('MonitorTasks', 'MonitorTaskAddContainer')

const KeyPersonMonitorTaskAdd = () => {
  const sceneCode = BaseStore.user.appInfo.sceneCode;
  const monitorLabel = Dict.map.monitorLabel[sceneCode];
  return (
    <MonitorTaskAddContainer 
      libType={1}
      taskLabel={monitorLabel.keyPerson.label}
      taskType='101501'
      alarmThreshold={85}
      taskListModuleName='keyPersonnelTaskView'
    />
  )
}
 
export default KeyPersonMonitorTaskAdd;

